<template>
  <div class="hold-additional-tasks">
    <!-- Image Upload Form -->
    <image-upload-form
      :task="currentTask"
      :tasks="tasks"
      @add-task="addTask"
      @update-task="updateTask"
    />
    <div class="p-0 w-100 d-flex justify-content-center align-items-center">
      <p class="mt-1 mb-0">
        {{ isCollapsed ? 'Show Tasks' : 'Hide Tasks' }}
      </p>
    </div>

    <div class="p-0 w-100 d-flex justify-content-center align-items-center">
      <a
        data-action="collapse"
        :class="{ rotate: isCollapsed }"
        @click="toggleCollapse"
      >
        <feather-icon
          :icon="isCollapsed ? 'ChevronDownIcon' : 'ChevronUpIcon'"
          size="25"
        />
      </a>
    </div>

    <!-- List of Preview Cards -->
    <b-collapse :visible="!isCollapsed">
      <div class="tasks-list">
        <task-item
          v-for="(task, index) in tasks"
          :key="index"
          :task="task"
          @delete="deleteTask(task.id)"
          @modify="modifyTask"
          @checkbox-change="handleCheckboxChange"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BButton, BIcon, BCollapse, BAvatar,
} from 'bootstrap-vue'
import ImageUploadForm from './components/ImageUploadForm.vue'
import TaskItem from './components/TaskItem.vue'
import { getCustomTasks } from './api' // Replace axios import with api
import axios from '@/plugins/axios'

export default {
  name: 'HoldAdditionalTasks',
  components: {
    ImageUploadForm,
    TaskItem,
    BButton,
    BIcon,
    BCollapse,
    BAvatar,
  },
  data() {
    return {
      tasks: [],
      isCollapsed: false, // Controls the collapse state of the tasks list
      checkedTasks: {}, // Keeps track of checked tasks
      currentTask: null, // Keeps track of the task being edited
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.getInitTasks()
    },
    getInitTasks() {
      axios
        .get('tasks/custom')
        .then(response => {
          this.tasks = response.data.items
          // this.slugs = response.data.items.map(item => item.slug)
          // console.log("SLUGS", this.slags)
        })
        .catch(error => {
          console.error('Error fetching initial tasks:', error)
        })
    },
    addTask(newTask) {
      // The task will be added to the server in TaskItem.vue
      this.tasks.unshift(newTask)
      this.currentTask = null // Reset currentTask after adding
    },
    updateTask(updatedTask) {
      const index = this.tasks.findIndex(task => task.id === updatedTask.id)
      if (index !== -1) {
        this.tasks.splice(index, 1, updatedTask)
      }
      this.currentTask = null // Reset currentTask after updating
    },
    deleteTask(taskId) {
      this.tasks = this.tasks.filter(task => task.id !== taskId)
      // Remove the task from checkedTasks if it exists
      if (this.checkedTasks[taskId]) {
        delete this.checkedTasks[taskId]
      }
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
    handleCheckboxChange({ id, checked }) {
      // Update the checkedTasks object
      if (checked) {
        this.checkedTasks[id] = true
      } else {
        delete this.checkedTasks[id]
      }
      // You can perform additional actions with checkedTasks as needed
      console.log('Checked tasks:', this.checkedTasks)
    },
    modifyTask(task) {
      // Set currentTask to the task being edited
      this.currentTask = task
    },
  },
}
</script>

<style scoped>
.hold-additional-tasks {
  margin: 0 auto;
}

.tasks-list {
  margin-top: 20px;
}
</style>
