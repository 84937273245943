<template>
  <div class="image-upload-form">
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <!-- Image Upload Section -->
        <b-col
          md="4"
          class="mb-2"
        >
          <b-form-group label="Upload Image">
            <p>Maximum allowed image size: {{ maxImageSizeKB }} KB</p>
            <p>Only .webp image files are allowed.</p>
            <b-form-file
              v-model="imageFile"
              :state="Boolean(imageFile || task ? true : false)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept="image/*"
              @change="handleImageUpload"
            />
            <div
              v-if="imageError"
              class="error-message"
            >
              {{ imageError }}
            </div>
            <!-- Image Preview -->
            <div
              v-if="imageSrc"
              class="image-preview mt-2"
            >
              <img
                :src="imageSrc"
                alt="Preview"
                width="100"
              >
            </div>
          </b-form-group>
        </b-col>

        <!-- Title Input Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Enter Title"
            label-for="title-input"
            class="w-100"
          >
            <b-form-input
              id="title-input"
              v-model="title"
              type="text"
              placeholder="Enter title"
              required
              @input="validateEn"
            />
            <div
              v-if="titleError"
              class="error-message"
            >
              {{ titleError }}
            </div>
          </b-form-group>
        </b-col>

        <!-- Title Input Field Ru -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Enter Russian Title"
            label-for="title-input"
            class="w-100"
          >
            <b-form-input
              id="title-input"
              v-model="titleru"
              type="text"
              placeholder="Enter Russian Title"
              required
              @input="validateRu"
            />
            <div
              v-if="titleruError"
              class="error-message"
            >
              {{ titleruError }}
            </div>
          </b-form-group>
        </b-col>

        <!-- Slug Input Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Enter Slug"
            label-for="slug-input"
            class="w-100"
          >
            <p class="text-left">
              Has to be unique for each task
            </p>
            <b-form-input
              id="slug-input"
              v-model="slug"
              type="text"
              placeholder="Enter slug (lowercase, no spaces)"
              required
              @input="validateSlug"
            />
            <div
              v-if="slugError"
              class="error-message"
            >
              {{ slugError }}
            </div>
          </b-form-group>
        </b-col>

        <!-- URL Input Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Enter URL"
            label-for="url-input"
            class="w-100"
          >
            <b-form-checkbox
              v-model="sendEmptyUrl"
              @change="onSendEmptyUrlChange"
            >
              Send empty URL
            </b-form-checkbox>
            <b-form-input
              id="url-input"
              v-model="urlInput"
              type="text"
              placeholder="Enter a valid URL"
              :disabled="sendEmptyUrl"
              :required="!sendEmptyUrl"
              @blur="validateURL"
            />
            <div
              v-if="urlError"
              class="error-message"
            >
              {{ urlError }}
            </div>
          </b-form-group>
        </b-col>

        <!-- Type Select Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Select Type"
            label-for="type-select"
            class="w-100"
          >
            <b-form-select
              id="type-select"
              v-model="type"
              :options="typeOptions"
              required
            />
          </b-form-group>
        </b-col>

        <!-- Subtype Select Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Select Subtype"
            label-for="subtype-select"
            class="w-100"
          >
            <b-form-select
              id="subtype-select"
              v-model="subtype"
              :options="subtypeOptions"
              required
            />
          </b-form-group>
        </b-col>

        <!-- Social Select Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Select Social"
            label-for="social-select"
            class="w-100"
          >
            <b-form-checkbox
              v-model="sendEmptySocial"
              @change="onSendEmptySocialChange"
            ><p class="mb-0">
              Send empty social
            </p></b-form-checkbox>
            <b-form-select
              id="social-select"
              v-model="social"
              :options="socialOptions"
              :disabled="sendEmptySocial"
              required
            />
          </b-form-group>
        </b-col>

        <!-- Reward Input Field -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Enter Reward"
            label-for="reward-input"
            class="w-100"
          >
            <b-form-input
              id="reward-input"
              v-model="reward"
              type="text"
              placeholder="Enter reward"
              required
            />
            <div
              v-if="rewardError"
              class="error-message"
            >
              {{ rewardError }}
            </div>
          </b-form-group>
        </b-col>

        <!-- isHidden Checkbox -->
        <b-col
          md="4"
          class="mb-2 d-flex align-items-center flex-column justify-content-end"
        >
          <b-form-group
            label="Is Hidden"
            label-for="isHidden-select"
            class="w-100"
          >
            <b-form-select
              id="isHidden-select"
              v-model="isHidden"
              :options="booleanOptions"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Submit Button -->
      <b-button
        variant="primary"
        type="submit"
      >
        {{ task ? 'Edit' : 'Submit' }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'ImageUploadForm',
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
  },
  props: {
    task: {
      type: Object,
      default: null,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      oldImageFile: null,
      maxImageSizeKB: 40,
      imageFile: null,
      imageSrc: this.task ? this.task.img : '',
      imageError: '',
      slug: this.task ? this.task.slug : '',
      slugError: '',
      urlInput: this.task ? this.task.url : '',
      urlError: '',
      sendEmptyUrl: false,
      // Other fields
      titleru: this.task ? this.task.title?.ru : '',
      titleruError: '',
      title: this.task ? this.task.title?.en : '',
      titleError: '',
      type: this.task ? this.task.type : 'social',
      subtype: this.task ? this.task.subtype : 'subscribe',
      social: this.task ? this.task.social : '',
      sendEmptySocial: false,
      reward: this.task ? this.task.reward : '',
      rewardError: '',
      isHidden: this.task && typeof this.task.isHidden === 'boolean' ? this.task.isHidden : false,
      // Options for selects
      typeOptions: ['social'],
      subtypeOptions: ['subscribe'],
      socialOptions: ['telegram', 'instagram', 'x', 'tiktok'],
      booleanOptions: [
        { value: false, text: 'Visible' },
        { value: true, text: 'Hidden' },
      ],
      // Base64 image data
      img: this.task ? this.task.img : '',
    }
  },
  watch: {
    task: {
      immediate: true,
      handler(newTask) {
        if (newTask) {
          // Update form fields with the task data
          this.imageSrc = newTask.img
          this.img = newTask.img
          this.slug = newTask.slug
          this.urlInput = newTask.url
          this.sendEmptyUrl = !newTask.url
          this.type = newTask.type
          this.subtype = newTask.subtype
          this.social = newTask.social
          this.sendEmptySocial = !newTask.social
          this.reward = newTask.reward
          this.isHidden = newTask.isHidden
          this.title = newTask.title?.en
          this.titleru = newTask.title?.ru
        } else {
          // Reset form if no task is provided
          this.resetForm()
        }
      },
    },
  },
  beforeDestroy() {
    // Revoke the object URL when the component is destroyed
    this.oldImageFile = null
  },
  methods: {
    handleImageUpload(event) {
      this.imageError = ''
      const file = event.target.files[0]
      if (file) {
        // Validate file type (only allow .webp)
        const fileType = file.type
        if (fileType !== 'image/webp') {
          this.imageError = 'Only .webp image files are allowed.'
          this.imageFile = null
          this.imageSrc = ''
          this.img = ''
          return // Stop further processing if file type is not .webp
        }

        // Validate file size
        const fileSizeKB = file.size / 1024
        if (fileSizeKB > this.maxImageSizeKB) {
          this.imageError = `Image size exceeds ${this.maxImageSizeKB} KB`
          this.imageFile = null
          this.imageSrc = ''
          this.img = ''
        } else {
          this.oldImageFile = file
          this.imageFile = file
          const reader = new FileReader()
          reader.onload = e => {
            this.imageSrc = e.target.result
          }
          reader.readAsDataURL(file)
        }
      }
    },

    validateRu() {
      this.titleruError = ''
      const russianRegex = /[\u0400-\u04FF]/
      // return russianRegex.test(text);
      if (!this.titleru) {
        this.titleruError = 'Title is required.'
      }
      if (!russianRegex.test(this.titleru)) {
        this.titleruError = 'Title must be Russian.'
      }
    },

    validateEn() {
      const englishRegex = /[a-zA-Z\s]/
      this.titleError = ''
      if (!this.title) {
        this.titleError = 'Title is required.'
      }
      if (!englishRegex.test(this.title)) {
        this.titleError = 'Title must be English.'
      }
    },

    validateSlug() {
      this.slugError = ''

      const slugs = this.tasks.map(item => item.slug)
      const slugExists = slugs.includes(this.slug)

      if (!this.slug) {
        this.slugError = 'Slug is required.'
      } else if (/\s/.test(this.slug)) {
        this.slugError = 'Slug cannot contain spaces.'
      } else if (this.slug !== this.slug.toLowerCase()) {
        this.slugError = 'Slug must be in lowercase.'
      } else if (slugExists && (!this.task || this.slug !== this.task.slug)) {
        // Check if slug exists in tasks array, ignoring the current task if in edit mode
        this.slugError = 'Slug must be unique.'
      }
    },
    validateURL() {
      this.urlError = ''
      if (this.sendEmptyUrl) {
        this.urlInput = ''
        return
      }
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' // Protocol
        + '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' // Domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR IP (v4) address
        + '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' // Port and path
        + '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' // Query string
          + '(\\#[-a-zA-Z\\d_]*)?$',
        'i',
      )
      if (this.urlInput && !urlPattern.test(this.urlInput)) {
        this.urlError = 'Please enter a valid URL.'
      } else if (!this.urlInput) {
        this.urlError = 'URL is required.'
      }
    },
    onSendEmptySocialChange() {
      if (this.sendEmptySocial) {
        this.social = ''
      }
    },
    onSendEmptyUrlChange() {
      if (this.sendEmptyUrl) {
        this.urlInput = ''
        this.urlError = ''
      }
    },
    handleSubmit() {
      this.validateSlug()
      this.validateURL()
      this.validateEn()
      this.validateRu()

      // Check for errors before proceeding
      if (this.imageError || this.slugError || this.urlError || this.rewardError || this.titleError || this.titleruError) {
        return
      }

      // Set the image data (use imageSrc or imageFile if available)
      const newImage = this.imageSrc || ''

      // Create new task object with updated image
      const newTask = {
        id: this.task ? this.task.id : 0,
        slug: this.slug,
        url: this.sendEmptyUrl ? '' : this.urlInput,
        img: newImage, // Use newImage here
        title: { en: this.title, ru: this.titleru },
        type: this.type,
        subtype: this.subtype,
        social: this.sendEmptySocial ? '' : this.social,
        reward: this.reward,
        isHidden: this.isHidden,
        createdAt: this.task ? this.task.createdAt : 0,
        updatedAt: this.task ? this.task.updatedAt : 0,
        imageFile: this.imageFile, // Pass the actual image file
      }

      if (this.task) {
        // Emit update task event
        // if(!this.task.imageFile) {
        //   newTask.imageFile = this.oldImageFile
        // }
        this.$emit('update-task', newTask)
      } else {
        // Emit add task event
        this.$emit('add-task', newTask)
      }

      // Reset the form after submission
      this.resetForm()
    },
    resetForm() {
      this.title = ''
      this.imageFile = null
      this.imageSrc = ''
      this.img = ''
      this.imageError = ''
      this.slug = ''
      this.slugError = ''
      this.urlInput = ''
      this.urlError = ''
      this.sendEmptyUrl = false
      this.type = 'social'
      this.subtype = 'subscribe'
      this.social = ''
      this.sendEmptySocial = false
      this.reward = ''
      this.rewardError = ''
      this.isHidden = false
    },
  },
}
</script>

<style scoped>
.image-upload-form {
  /* max-width: 800px; */
  margin: 0 auto;
}

.error-message {
  color: red;
  font-size: 0.9em;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
}
</style>
