<template>
  <b-card class="mt-1">
    <div
      md="1"
      class="d-flex align-items-center position-relative"
    >
      <!-- Checkbox for Selection -->
      <b-form-checkbox
        v-model="isChecked"
        class="m-0"
        :disabled="true"
        @change="onCheckboxChange"
      />
      <!-- Image Display -->
      <b-img
        v-if="imageSrc"
        class="m-1"
        :src="imageSrc"
        fluid
        alt="Uploaded Image"
        width="60"
        height="60"
        thumbnail
      />
      <!-- Task Details -->
      <div class="w-50">
        <h5>{{ task.title.en }}</h5>
        <p v-if="task.social">
          Social Platform: {{ task.social }}
        </p>
        <p>Reward: {{ task.reward }}</p>
      </div>
      <!-- Action Buttons -->
      <div class="ml-1 d-flex flex-column">
        <b-button
          class="mb-1"
          variant="primary"
          size="sm"
          :disabled="!isSent"
          @click="onModify"
        >
          Edit
        </b-button>
        <b-button
          class="mb-1"
          variant="danger"
          size="sm"
          @click="onDelete"
        >
          Delete
        </b-button>
        <b-button
          variant="success"
          size="sm"
          :disabled="isSent || isSending"
          @click="onSend"
        >
          Send
        </b-button>
      </div>
      <p class="ml-1">
        Press send to publish the task
      </p>
    </div>
    <b-col
      md="2"
      class="d-flex align-items-center justify-content-center"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import axios from '@/plugins/axios' // Assuming Axios is configured with a base URL

export default {
  name: 'TaskItem',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BFormCheckbox,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageObjectUrl: null,
      isChecked: !this.task.isHidden,
      isSent: !!this.task.id,
      isSending: false,
    }
  },
  computed: {
    imageSrc() {
      if (this.task.img) {
        return this.task.img // Use the updated task.img directly
      } if (this.task.imageFile) {
        // Revoke previous object URL if it exists
        if (this.imageObjectUrl) {
          URL.revokeObjectURL(this.imageObjectUrl)
        }
        // Create a new object URL
        this.imageObjectUrl = URL.createObjectURL(this.task.imageFile)
        return this.imageObjectUrl
      }
      return null
    },
  },
  watch: {
    task: {
      handler(newTask, oldTask) {
        // Reset isSent when the task is modified
        if (JSON.stringify(newTask) !== JSON.stringify(oldTask) && this.isSent) {
          this.isSent = false
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Revoke the object URL when the component is destroyed
    if (this.imageObjectUrl) {
      URL.revokeObjectURL(this.imageObjectUrl)
    }
  },
  methods: {
    onDelete() {
      if (this.task.id) {
        // Send DELETE request to the server
        axios
          // .delete(`http://localhost:3000/tasks/${this.task.id}`)
          .delete(`tasks/custom/${this.task.id}`)
          .then(response => {
            console.log('Task deleted from server:', response.data)
            // Emit an event to the parent component to handle deletion
            this.$emit('delete', this.task.id)
          })
          .catch(error => {
            console.error('Error deleting task:', error)
          })
      } else {
        // Task hasn't been sent to the server yet, just delete it locally
        this.$emit('delete', this.task.id)
      }
    },
    onModify() {
      // Emit an event to the parent component to handle modification
      this.$emit('modify', this.task)
    },
    onCheckboxChange() {
      // Emit the checkbox state to the parent component
      this.$emit('checkbox-change', {
        id: this.task.id,
        checked: this.isChecked,
      })
    },
    onSend() {
      if (this.isSending) return

      // Validate if imageFile is not null before proceeding
      // if (!this.task.imageFile && !this.task.img) {
      //   console.error('Image file is required')
      //   return // Exit early if no image file is present
      // }

      this.isSending = true
      // Create a FormData object
      const formData = new FormData()

      // Append all necessary fields to the FormData
      formData.append('slug', this.task.slug)
      formData.append('url', this.task.url)
      formData.append('type', this.task.type)
      formData.append('subtype', this.task.subtype)
      formData.append('social', this.task.social)
      formData.append('reward', this.task.reward)
      formData.append('title', JSON.stringify(this.task.title))
      formData.append('isHidden', this.task.isHidden)

      // Append the image file if it exists
      if (this.task.imageFile) {
        formData.append('image', this.task.imageFile)
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      if (this.task.id) {
        // Task exists on the server, send PATCH request without 'id' in the data
        axios
          .patch(`/tasks/custom/${this.task.id}`, formData, config)
          .then(response => {
            console.log('Task updated successfully:', response.data)
            this.isSent = true
            Object.assign(this.task, response.data)
            // Clear imageFile and revoke object URL
            this.task.imageFile = null
            if (this.imageObjectUrl) {
              URL.revokeObjectURL(this.imageObjectUrl)
              this.imageObjectUrl = null
            }
            if (response.data.img) {
              this.task.img = response.data.img
            }
          })
          .catch(error => {
            console.error('Error updating task:', error)
          })
          .finally(() => {
            this.isSending = false
          })
      } else {
        // Task doesn't exist on the server, send POST request without 'id' in the data
        axios
          .post('/tasks/custom', formData, config)
          .then(response => {
            console.log('Task created successfully:', response.data)
            this.isSent = true
            Object.assign(this.task, response.data)
            // Clear imageFile and revoke object URL
            this.task.imageFile = null
            if (this.imageObjectUrl) {
              URL.revokeObjectURL(this.imageObjectUrl)
              this.imageObjectUrl = null
            }
            if (response.data.img) {
              this.task.img = response.data.img
            }
          })
          .catch(error => {
            console.error('Error creating task:', error)
          })
          .finally(() => {
            this.isSending = false
          })
      }
    },
  },
}
</script>

<style scoped>
.b-card {
  padding: 15px;
}

.b-card h5 {
  margin: 0;
}

.b-img {
  max-width: 60px;
  max-height: 60px;
}

.ml-1 > * {
  margin-right: 5px;
}
</style>
